<template>
  <div>
    <img
      class="image"
      v-for="(image, i) in images"
      :src="image"
      :key="i"
      @click="index = i"
    />
    <vue-gallery-slideshow
      :images="images"
      :index="index"
      @close="index = null"
    ></vue-gallery-slideshow>
  </div>
</template>

<script>
import VueGallerySlideshow from "vue-gallery-slideshow";
export default {
  name: "gallery",
  props: {
    album: { default: "https://photos.app.goo.gl/rUowaefUprkhRbwbA" }
  },
  components: {
    VueGallerySlideshow
  },
  data() {
    return {
      images: [],
      index: null
    };
  },
  mounted() {
    this.getImages();
  },
  methods: {
    getImages: function() {
      this.$req
        .post(`getPhotosFromAlbum`, {
          album: this.album
        })
        .then(r => {
          this.images = r.data.slice(0, this.images.length - 4);
        })
        .catch(e => console.log(e));
    }
  }
};
</script>

<style>
.image {
  width: 100px;
  height: 100px;
  background-size: cover;
  cursor: pointer;
  margin: 5px;
  border-radius: 3px;
  border: 1px solid lightgray;
  object-fit: contain;
}

.vgs_container {
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  overflow: hidden;
  max-width: 100vh;
  margin: auto;
  left: 0.5rem;
  top: calc(50% - 140px) !important;
  right: 0.5rem;
  height: 280px !important;
  border-radius: 12px;
  background-color: #000;
}
</style>
