<template>
  <div
    class="campaign wfill"
    :style="{ padding: $mq == 'mobile' ? '20px' : '40px' }"
  >
    <div style="position: relative; text-align: center">
      <img
        :class="{ hiddenOnPage: isPage }"
        v-if="$mq == 'mobile'"
        :src="src.url"
        :alt="src.alternativeText"
        :longdesc="src.caption"
        width="80%"
        height="auto"
        style="margin: auto"
      />
      <img
        :class="{ hiddenOnPage: isPage }"
        v-else
        :src="src.url"
        :alt="src.alternativeText"
        :longdesc="src.caption"
        :width="isBanner ? '88%' : 'auto'"
        :height="isBanner ? 'auto' : 360"
        style="margin: auto"
        :style="{
          objectFit: 'cover',
          objectPosition: 'center',
        }"
      />
    </div>
    <div
      v-if="showImages && campaign.Images && campaign.Images.length > 0"
      class="flex items-center"
      :class="{ hiddenOnPage: isPage }"
    >
      <div
        :class="{ hiddenOnPage: isPage }"
        @click="scrollImages(-200)"
        class="flex items-center centered pointer h40 w40 br20 f5f5f5 hover-ccc active-aaa pl8"
      >
        <i
          style="right: 2px; top: 1px"
          class="relative black chevron left icon"
        ></i>
      </div>
      <div
        :id="carouselId"
        :class="{ hiddenOnPage: isPage }"
        class="images flex"
      >
        <img
          v-for="i in [campaign.Poster, ...campaign.Images]"
          :height="$mq == 'mobile' ? 80 : 160"
          :class="{ inactive: i.url != src }"
          class="campaign_img"
          :key="i.url"
          :src="i.url"
          @click="src = i"
          :alt="i.alternativeText"
        />
      </div>
      <div
        :class="{ hiddenOnPage: isPage }"
        @click="scrollImages(200)"
        class="flex items-center centered pointer h40 w40 br20 f5f5f5 hover-ccc active-aaa"
        style="margin-left: 6%"
      >
        <i
          style="left: 2px; top: 1px"
          class="relative black chevron right icon"
        ></i>
      </div>
    </div>
    <h1 class="title mt24 spaced flex fs24 mobile-fs16">
      <span :class="{ hiddenOnPage: isPage }">{{ campaign.Title }}</span>
      <div>
        <button
          :class="{ hiddenOnPage: isPage }"
          @click="$router.push(`/jury/${campaign.id}`)"
          v-if="juryEmails.includes(userEmail)"
          class="mr12"
        >
          Jury Evaluation
        </button>
        <button
          :class="{ hiddenOnPage: isPage }"
          @click="$router.push(`/juryresults/${campaign.id}`)"
          v-if="roles && campaign.type != 'livequiz' && juryEmails.length > 0"
          class="mr12 brown500"
        >
          Jury Results
        </button>
        <button
          class="mr12"
          v-if="roles && campaign.quiz"
          @click="$router.push(`/quiz/${campaign.quiz.id}/rankings`)"
        >
          Quiz Results
        </button>
        <button
          v-if="roles && campaign.type != 'livequiz'"
          @click="$router.push(`/ideace/${campaign.id}/registrations`)"
          class="black"
        >
          View Registrations {{ campaign.id }}
        </button>
      </div>
    </h1>

    <!-- <button
      v-if="campaign.type == 'campaign' && isActive"
      @click="$bus.redirect(campaign.participationLink, false, true)"
      class="participation_button mt16 black"
    >
      {{ campaign.participationLinkText || "Participate Now" }}
    </button> -->
    <div v-if="campaign.noRegistrations">
      <button
        v-if="campaign.participationLink && isActive"
        @click="$bus.redirect(campaign.participationLink)"
        class="participation_button mt16 black"
      >
        {{ campaign.participationLinkText || "Participate Now" }}
      </button>
    </div>
    <div v-if="campaign.type != 'livequiz' && !campaign.noRegistrations">
      <button
        v-if="!$bus.isLoggedIn && !campaign.quickRegistration"
        class="mt20 brown500"
        style="margin-left: 6%; margin-right: 6%"
        @click="$router.push('/auth?redirectFrom=' + campaign.Title)"
      >
        Please register to participate
      </button>
      <!-- QUICK REGISTRATION -->

      <div
        v-if="!submission && campaign.quickRegistration"
        style="margin-left: 6%; margin-right: 6%; background-color: #f1f1f1"
        class="mt12 br4 py20 px24 vertical flex"
      >
        <h4 class="mxauto">Registration</h4>
        <div class="mxauto mt8 vertical flex" style="width: 90%">
          <input
            v-if="!$bus.isLoggedIn"
            style="max-width: 300px; width: 100%"
            v-model="quickReg.name"
            class="mxauto mt12 ui input"
            placeholder="Name"
          />
          <input
            v-if="!$bus.isLoggedIn"
            style="max-width: 300px; width: 100%"
            type="email"
            class="mxauto mt12 ui input"
            v-model="quickReg.email"
            placeholder="Email"
          />
          <input
            v-if="!$bus.isLoggedIn"
            style="max-width: 300px; width: 100%"
            type="password"
            class="mxauto mt12 ui input"
            v-model="quickReg.password"
            placeholder="Password"
          />
          <input
            v-if="!$bus.isLoggedIn"
            style="max-width: 300px; width: 100%"
            type="tel"
            class="mxauto mt12 ui input"
            v-model="quickReg.contact"
            placeholder="Contact e.g. +91-999-999-9999"
          />
          <button
            @click="quickRegister"
            style="max-width: 300px; width: 100%"
            class="mxauto mt12 black x"
          >
            Register Now
          </button>
          <button
            style="max-width: 300px; width: 100%"
            class="flex googleAuth mxauto mt12 px12 py8 white raised"
            @click="socialAuth(googleProvider)"
          >
            <img
              height="24"
              src="https://developers.google.com/identity/images/g-logo.png"
            />
            <span class="fw500 mauto" style="color: #7b7978"
              >Sign in with Google</span
            >
          </button>
          <button
            style="max-width: 300px; width: 100%"
            class="flex facebookAuth mxauto mt12 px12 py8 raised"
            @click="socialAuth(facebookProvider)"
          >
            <img
              height="24"
              src="https://static.xx.fbcdn.net/rsrc.php/v3/yT/r/U3VrQWQBx7m.png"
            />
            <span class="mauto fw500 m" style="color: white"
              >Continue with Facebook</span
            >
          </button>
        </div>
      </div>
      <div
        v-if="!submission && campaign.quickRegistration && !isActive"
        class="mt20 py24 px32"
        style="margin-left: 6%; margin-right: 6%; background-color: #f1f1f1"
      >
        Registrations are closed for {{ campaign.Title }}
      </div>
      <div
        v-if="submission && campaign.quickRegistration"
        class="mt20 py24 px32"
        style="margin-left: 6%; margin-right: 6%; background-color: #f1f1f1"
      >
        <h4 class="fs16 mxauto mb12">You have successfully registered!</h4>
        Registered as <span class="bold">{{ $bus.user.name }}</span> -
        {{ $bus.user.email }}
        <iframe
          v-if="campaign.postRegistrationVideoUrl"
          style="width: 100%; margin-top: 20px"
          height="400"
          :src="campaign.postRegistrationVideoUrl"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div v-if="$bus.isLoggedIn">
        <!-- FOR COMPLETED PROFILES-->
        <button
          v-if="
            campaign.participationLink &&
            isActive &&
            (!campaign.noRegistrations ? submission : true) &&
            hasRequiredTeamMembers
          "
          @click="$bus.redirect(campaign.participationLink)"
          class="participation_button mt16 black"
        >
          {{ campaign.participationLinkText || "Participate Now" }}
        </button>
        <div
          v-if="$bus.profileComplete"
          style="padding-left: 6%; padding-right: 6%"
          class="mt20"
        >
          <div
            v-if="!campaign.noRegistrations && eligible"
            style="background-color: #f1f1f1"
            class="br4 py20 px24"
          >
            <div v-if="!registrationsClosed">
              <h4 class="mb12 fs16">
                {{
                  (submission || hasSubmission) &&
                  $bus.user &&
                  hasRequiredTeamMembers &&
                  !quizEntry
                    ? "You have successfully registered!"
                    : "Registration"
                }}
              </h4>
              <h5 v-if="campaign.pageName == 'LEDEG'">
                Please confirm your age
              </h5>
              <input
                v-if="campaign.pageName == 'LEDEG'"
                v-model="yourAge"
                class="mt8 mb16 py10"
                placeholder="Your Age"
                type="number"
              />
              <span
                v-if="
                  campaign.teamMembers &&
                  teamMembers.length < campaign.teamMembers - 1
                "
              >
                <h5>Add Team Members</h5>
                <p class="mb12 mt8">
                  You are allowed upto {{ campaign.teamMembers - 1 }} additional
                  team member
                </p>
                <input
                  v-model="teamMember"
                  placeholder="Email"
                  class="py10"
                  type="email"
                  required
                />
                <input
                  v-if="campaign.pageName == 'LEDEG'"
                  v-model="teamMemberAge"
                  class="ml4 py10"
                  placeholder="Age"
                  type="number"
                />
                <button @click="addTeamMember" class="black ml8 br0 px12 py8">
                  Add Team Member's Email
                </button>
              </span>
              <h5 class="mt12 mb8">Current Team</h5>
              <p>1) {{ $bus.user.name }} - {{ $bus.user.email }}</p>
              <p v-for="(m, i) in teamMembers" :key="m">
                {{ i + 2 }}) {{ m.name }} - {{ m.email }}
                <i
                  v-if="campaign.quiz && !quizEntry"
                  @click="deleteTeamMember(ix)"
                  style="font-size: 12px"
                  class="ml8 pointer trash icon"
                ></i>
              </p>
              <br />
              <button
                v-if="!submission && $bus.user && hasRequiredTeamMembers"
                class="noshrink mt12 black"
                @click="createSubmission"
              >
                Register Now
              </button>
              <button
                v-if="
                  (submission || hasSubmission) &&
                  $bus.user &&
                  teamEvent &&
                  hasRequiredTeamMembers &&
                  !quizEntry
                "
                class="mt12 black"
                @click="updateSubmission"
              >
                Update Team
              </button>

              <p v-if="!hasRequiredTeamMembers" style="color: tomato">
                This event requires at least
                {{ campaign.requiredTeamMembers }} members
              </p>
              <p v-if="quizEntry" style="color: tomato">
                You have already taken this quiz.
              </p>
              <iframe
                v-if="
                  campaign.postRegistrationVideoUrl &&
                  submission &&
                  $bus.user &&
                  hasRequiredTeamMembers &&
                  !quizEntry
                "
                style="width: 100%; margin-top: 20px"
                height="400"
                :src="campaign.postRegistrationVideoUrl"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div v-else>
              Registrations for {{ campaign.Title }} have been closed.
            </div>
          </div>

          <div v-if="!eligible">
            <button
              @click="$router.push(`/profile?redirectFrom=${campaign.Title}`)"
              :class="[
                ineligibleText.includes('You are ineligible for this event')
                  ? 'black'
                  : 'brown500',
              ]"
            >
              {{ ineligibleText }}
            </button>
          </div>

          <div class="mt12" v-if="(submission || hasSubmission) && $bus.user">
            <p v-if="!hasBegun" class="brown500">
              You have successfully registered!
              <span v-if="!campaign.noSubmissions"
                >Submissions open {{ getDateLabel(campaign.Start) }}</span
              >
            </p>
            <h5 class="mt12 mb8">Your Team</h5>
            <p
              v-for="(u, i) in submission ? submission.users : []"
              :key="u.email"
            >
              {{ i + 1 }}) {{ u.name }} - {{ u.email }}
            </p>

            <!-- Submissions -->
            <div
              v-if="isActive && !campaign.noSubmissions"
              class="mt20 fileUpload"
            >
              <h3 class="fs14 mb12 ml4 mt4">Submissions</h3>
              <input
                @change="submitFile($event)"
                type="file"
                ref="fileInput"
                id="fileInput"
                style="display: none"
              />
              <input
                @change="submitCover($event)"
                type="file"
                ref="coverInput"
                id="coverInput"
                style="display: none"
              />

              <div class="flex wrap">
                <div
                  class="fileView mt8 mr8"
                  v-for="file in submission.files"
                  :key="file.url"
                >
                  <p
                    style="
                      font-size: 8px;
                      position: absolute;
                      left: 8px;
                      bottom: 4px;
                    "
                  >
                    {{ round(file.size / 1000) }} MB
                  </p>
                  <i
                    style="margin: auto 0px auto 12px; color: #8d6235"
                    class="file icon"
                  ></i>
                  <p
                    @click="$bus.redirect(file.url)"
                    style="margin: auto 12px auto 12px"
                    class="fileName pointer"
                  >
                    {{ file.name }}
                  </p>
                  <i
                    @click="deleteFile(file.id)"
                    style="margin: auto 12px auto 8px"
                    class="fileDelete pointer trash icon"
                  ></i>
                </div>
                <button @click="simulateClick" class="mt8 addFileButton">
                  <i class="file icon"></i> Add File
                </button>
              </div>
              <div
                v-if="campaign.showUserSubmissions"
                class="mt12 flex vertical"
                style="width: 200px"
              >
                <h3 class="fs14 mb12 ml4 mt">Details</h3>
                <input
                  class="mb12"
                  @change="updateTitle"
                  v-model="submission.title"
                  placeholder="Add Title"
                />
                <div
                  @click="clickCoverUpload"
                  class="centered flex items-center"
                  v-if="submission.cover"
                  :style="{
                    background: `url(${submission.cover.url}) center / cover no-repeat`,
                  }"
                  style="width: 200px; height: 200px; color: #aaa"
                >
                  <div
                    class="pointer p8 br2 items-center centered flex"
                    style="background: white; border: 1px solid #eee"
                  >
                    <i class="image icon"></i> Update Cover
                  </div>
                </div>
                <button
                  v-if="!submission.cover"
                  @click="clickCoverUpload"
                  style="width: 100%; height: 200px"
                  class="mt8 addFileButton"
                >
                  <i class="image icon"></i> Upload Cover
                </button>
              </div>
              <!-- <a v-for="file in submission.files" :key="file.url" @click="$bus.redirect(file.url)">{{file.name}}</a> -->
            </div>
            <div v-if="hasEnded" class="mt20 bold" style="color: #8d6235">
              Thank you for your participation in {{ campaign.Title }}
            </div>
          </div>
        </div>
        <a
          v-else
          style="padding-left: 6%; padding-right: 6%"
          class="mt12"
          href="/#/profile"
        >
          <button class="brown500">
            Please complete your profile to participate!
          </button>
        </a>
      </div>
    </div>
    <div v-if="campaign.googlePhotosUrl">
      <h6 class="fs16 mt32 mb12" style="padding-left: 6%">Photos</h6>
      <gallery-widget
        style="padding-left: calc(6% - 8px)"
        :album="campaign.googlePhotosUrl"
      />
    </div>
    <div
      v-if="
        campaign.showUserSubmissions &&
        featuredSubmissions &&
        featuredSubmissions.length > 0
      "
      style="padding-left: 6%"
    >
      <h6 class="fs16 mt32 mb16">Featured</h6>
      <div class="flex wrap">
        <div
          v-for="sub in featuredSubmissions"
          :key="sub.id"
          class="pointer shadow br2 mr12 raised"
          @click="$bus.redirect(sub.files[0].url, false, true)"
          style="width: 200px; height: 260px"
        >
          <div
            :style="{
              background: `url(${sub.cover.url}) center / cover no-repeat`,
            }"
            style="width: 200px; height: 200px"
          ></div>
          <div class="px12 py8" style="border-top: 1px solid #eee">
            <h6 class="fs12 pt2">{{ sub.title }}</h6>
            <p class="fs12 nnn mt4">
              by <span v-for="u in sub.users" :key="u.id">{{ u.name }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <p
      :class="{ hiddenOnPage: isPage }"
      class="info mt24"
      v-html="campaign.Results || ''"
    ></p>
    <p
      :class="{ hiddenOnPage: isPage }"
      class="info mt24"
      v-html="campaign.Information"
    ></p>
    <div
      :class="{ hiddenOnPage: isPage }"
      v-for="s in campaign.sections"
      :key="s.id"
      :style="processStyles(s.styles)"
    >
      <div v-if="s.type == 'html'" v-html="s.html"></div>
      <div
        v-if="s.type == 'text'"
        style="padding: 40px 6%"
        v-html="s.text"
      ></div>
      <div
        v-if="s.type == 'textNimage'"
        class="campaignTextNImage"
        style="padding: 40px 6%"
      >
        <img
          style="display: block"
          height="100%"
          :src="s.images[0].url"
          alt=""
        />
        <div v-html="s.text" class="px20 text"></div>
      </div>
      <img
        v-if="$mq == 'desktop' && s.images && s.images[0] && s.type == 'image'"
        class="mxauto"
        style="display: block; height: 70vh; text-align: center"
        :src="s.images[0].url"
      />
      <img
        v-if="$mq != 'desktop' && s.images && s.images[0] && s.type == 'image'"
        style="width: 100%"
        :src="s.images[0].url"
      />
    </div>
    <iframe
      :class="{ hiddenOnPage: isPage }"
      v-if="campaign.videoUrl"
      style="width: 80%; margin-left: 6%; margin-top: 20px"
      height="400"
      :src="campaign.videoUrl"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <div
      :class="{ hiddenOnPage: isPage }"
      v-if="campaign.Files && campaign.Files.length > 0"
    >
      <h6 class="fs16 mt32 mb12" style="padding-left: 6%">Attachments</h6>
      <a
        class="files pointer mt4"
        v-for="f in campaign.Files"
        @click="$bus.redirect(f.url, false, true)"
        :key="f.name"
        >{{ f.name }}
      </a>
    </div>
    <div
      :class="{ hiddenOnPage: isPage }"
      style="padding-left: 6%"
      v-for="(g, i) in campaign.FileGroups"
      :key="i + '-' + g.title"
    >
      <h6 class="fs16 mt32 mb12">{{ g.Title }}</h6>
      <div
        class="fileView mt8 mr8"
        v-for="file in g.Files"
        :key="file.url"
        style="background: #eee; display: inline-flex; padding-right: 12px"
      >
        <p style="font-size: 8px; position: absolute; left: 8px; bottom: 4px">
          {{ round(file.size / 1000) }} MB
        </p>
        <i
          style="margin: auto 0px auto 12px; color: #8d6235"
          class="file icon"
        ></i>
        <p
          @click="$bus.redirect(file.url, false, true)"
          style="margin: auto 12px auto 12px"
          class="fileName pointer"
        >
          {{ file.name }}
        </p>
      </div>
    </div>
    <div :class="{ hiddenOnPage: isPage }" v-if="campaign.noRegistrations">
      <button
        v-if="campaign.participationLink && isActive"
        @click="$bus.redirect(campaign.participationLink, false, true)"
        class="participation_button mt16 black"
      >
        {{ campaign.participationLinkText || "Participate Now" }}
      </button>
    </div>
    <div :class="{ hiddenOnPage: isPage }" v-else>
      <button
        v-if="!$bus.isLoggedIn"
        class="mt20 brown500"
        style="margin-left: 6%; margin-right: 6%"
        @click="$router.push('/auth?redirectFrom=' + campaign.Title)"
      >
        Please register to participate
      </button>
      <div v-else>
        <!-- FOR COMPLETED PROFILES-->
        <button
          v-if="
            campaign.participationLink &&
            isActive &&
            (!campaign.noRegistrations ? submission : true) &&
            hasRequiredTeamMembers
          "
          @click="$bus.redirect(campaign.participationLink, false, true)"
          class="participation_button mt16 black"
        >
          {{ campaign.participationLinkText || "Participate Now" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Notyf } from "notyf";
import $ from "jquery";
import galleryWidget from "../components/gallery-widget";
import moment from "moment";
import st from "storerestapi";
export default {
  name: "campaign",
  props: {
    campaign: { required: true },
    showImages: { default: true },
    isPage: { default: false },
  },
  components: {
    galleryWidget,
  },
  metaInfo() {
    return {
      title:
        this.campaign?.SEO?.metaTitle ||
        (this.campaign?.type == "campaign"
          ? "Ethos | Arcause"
          : "Ethos | Ideace"),
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: this.campaign?.SEO?.metaDescription || "",
        },
        {
          hid: "keywords",
          name: "keywords",
          content: this.campaign?.SEO?.metaKeywords || "",
        },
      ],
    };
  },
  data() {
    return {
      carouselId: this.$bus.random(),
      src: {},
      loaded: false,
      hasSubmission: false,
      submission: null,
      quizEntry: null,
      roles: undefined,
      yourAge: "",
      teamMember: "",
      teamMemberAge: "",
      teamMembers: [],
      featuredSubmissions: [],
      quickReg: {
        name: "",
        email: "",
        password: "",
        contact: "",
      },
      /* eslint-disable-next-line no-undef */
      googleProvider: new firebase.auth.GoogleAuthProvider(),
      /* eslint-disable-next-line no-undef */
      facebookProvider: new firebase.auth.FacebookAuthProvider(),
    };
  },
  watch: {
    campaign: {
      handler: function () {
        /* eslint-disable no-undef */
        let slidesToShow = this.$mq == "mobile" ? 1 : 4;
        $(`#${this.ID}`).slick({
          slidesToShow,
          arrows: true,
        });
      },
      deep: true,
    },
  },
  computed: {
    eligible: function () {
      console.log("Testing eligibility");
      return this.checkEligible(this.$bus.user);
    },
    ineligibleText: function () {
      const u = this.$bus.user;

      if (!u) {
        return "You are ineligible for this event.";
      }
      if (
        this.campaign?.freshGraduates &&
        u.type != "Student" &&
        (date.getMonth() < 7
          ? date.getFullYear() - 1 != u.yearOfGraduation
          : date.getFullYear() != u.yearOfGraduation)
      ) {
        return "You are ineligible for this event. Only for students & fresh graduates.";
      }

      let type = this.campaign.ParticipantType;

      type = type != "Faculty" ? type + "s" : type;

      if (
        this.campaign.ParticipantType &&
        this.campaign.ParticipantType != "All" &&
        this.campaign.ParticipantType != u.type
      ) {
        return `You are ineligible for this event. Only for ${type}`;
      }
      // replace underscore with space
      const field = this.campaign.ParticipantField
        ? this.campaign.ParticipantField.replace("_", " ")
        : "";

      if (field && field != "All" && field != u.field) {
        return `You are ineligible for this event. Only for the ${field} field.`;
      }

      return `Please complete profile to participate.`;
    },
    registrationsClosed: function () {
      var to = this.campaign.End;
      var regD = this.campaign.registrationDeadline;
      if (regD) {
        return moment(regD).unix() < moment().unix();
      }
      return moment(to).unix() < moment().unix();
    },
    isActive: function () {
      var from = this.campaign.Start;
      var to = this.campaign.End;
      let convert = (m) => parseInt(m.format("YYYYMMDD"));
      return (
        convert(moment(from)) <= convert(moment()) &&
        convert(moment(to)) >= convert(moment())
      );
    },
    hasRequiredTeamMembers: function () {
      if (!this.campaign.requiredTeamMembers) {
        return true;
      }

      if (this.campaign.requiredTeamMembers <= this.teamMembers.length + 1) {
        return true;
      }
      return false;
    },
    teamEvent: function () {
      const membersRequired = this.campaign.teamMembers;
      return membersRequired && membersRequired > 1;
    },
    hasBegun: function () {
      var from = this.campaign.Start;
      console.log("from", from);
      return moment(from).unix() <= moment().unix();
    },
    hasEnded: function () {
      var to = this.campaign.End;
      return moment(to).unix() < moment().unix();
    },
    juryEmails: function () {
      return (this.campaign.jury || []).map((j) => j.email);
    },
    userEmail: function () {
      return this.$bus.user ? this.$bus.user.email : "";
    },
    isBanner: function () {
      if (
        this.campaign.hasBanner &&
        this.campaign?.Images &&
        this.campaign?.Images?.length > 0
      ) {
        return (
          this.src &&
          this.src.url &&
          this.src.url == this?.campaign?.Images?.[0]?.url
        );
      }
      return false;
    },
  },
  methods: {
    round: function (n) {
      return Math.round(n);
    },
    scrollImages: function (n) {
      let scrollLeft = $(".images").scrollLeft();
      $(".images").animate({ scrollLeft: scrollLeft + n }, 400);
    },
    getFeaturedSubmissions: function () {
      this.$req
        .post(`/getFeaturedSubmissions`)
        .then((r) => {
          this.featuredSubmissions = r.data.filter(
            (sub) => sub.files && sub.files[0]
          );
        })
        .catch((e) => console.log(e));
    },
    quickRegister: function () {
      if (this.$bus.isLoggedIn) {
        this.createSubmission();
        return;
      }

      let fields = Object.values(this.quickReg);

      if (fields.includes("")) {
        alert("Please fill all fields");
        return;
      }

      let form = { ...this.quickReg };

      form["username"] = form["email"];
      delete form["contact"];

      form = {
        ...form,
        ...{
          type: "",
          levelOfEducation: "",
          yearOfGraduation: "",
          institute: "",
          notListedInstitute: "",
          field: "",
          contact: "",
          companyName: "",
          location: "",
          employmentStatus: "",
        },
      };
      this.$baseReq
        .post("auth/local/register", form)
        .then((r) => {
          // Handle success.
          this.$bus.user = r.data.user;
          this.$bus.token = r.data.jwt;
          st.setVar("user", r.data.user);
          st.setVar("token", r.data.jwt);
          this.createSubmission();
        })
        .catch((error) => {
          // Handle error.
          let msg = this.getErrorMessage(error);
          if (msg == "Email is already taken.") {
            this.$bus.notify("Email taken, attempting signin.");
            this.$baseReq
              .post("auth/local", {
                identifier: form.email,
                password: form.password,
              })
              .then((r) => {
                this.$bus.user = r.data.user;
                this.$bus.token = r.data.jwt;
                st.setVar("user", r.data.user);
                st.setVar("token", r.data.jwt);
                this.createSubmission();
              })
              .catch((error) => {
                // Handle error.
                alert(this.getErrorMessage(error));
              });
          } else {
            alert(msg);
          }
        });
    },
    getErrorMessage: function (error) {
      try {
        let message = error.response.data.data[0].messages[0].message;
        return message;
      } catch {
        return error;
      }
    },
    socialAuth: function (provider) {
      /* eslint-disable-next-line no-undef */
      firebase
        .auth()
        .signInWithPopup(provider)
        /* eslint-disable-next-line no-unused-vars */
        .then((result) => {
          /** @type {firebase.auth.OAuthCredential} */
          let user = result.user;
          let token = user.uid;
          this.$req
            .post("socialauth", {
              uid: token,
            })
            .then((r) => {
              st.setVar("user", r.data.user);
              st.setVar("token", r.data.jwt);
              this.$bus.user = r.data.user;
              this.$bus.token = r.data.jwt;
              // this.$router.push('/events')
              this.createSubmission();
            })
            .catch((e) => {
              alert("Authentication Failed");
              console.log(e);
            });
        })
        .catch((error) => {
          console.log(`Authentication Failed: ${error.message}`);
        });
    },
    scrollImagesRight: function () {
      $(".images").scrollLeft($(".images").scrollLeft() + 60);
    },
    scrollImagesLeft: function () {
      $(".images").scrollLeft($(".images").scrollLeft() - 60);
    },
    processStyles: function (styleArray) {
      let obj = {};
      if (!styleArray) {
        return obj;
      }
      for (let el of styleArray) {
        obj[el.property] = el.value;
      }
      return obj;
    },
    checkEligible: function (u) {
      let eligibl = true;
      const date = new Date();
      if (
        this.campaign.ParticipantType &&
        this.campaign.ParticipantType != "All"
      ) {
        eligibl = this.campaign.ParticipantType == u.type;
      }
      // replace underscore with space
      const field = this.campaign?.ParticipantField?.replace(/_/g, " ");

      if (field && field != "All") {
        eligibl = eligibl && field == u.field;
      }
      if (this.campaign?.freshGraduates) {
        eligibl =
          eligibl &&
          (u.type == "Student" ||
            (date.getMonth() < 7
              ? date.getFullYear() - 1 == u.yearOfGraduation
              : date.getFullYear() == u.yearOfGraduation));
      }
      // if (this.campaign.currentlyEnrolled && this.campaign.ParticipantType == 'Student') {
      //   eligibl = eligibl && u.yearOfGraduation >= parseInt(moment().format('YYYY'))
      // }
      return eligibl;
    },
    simulateClick: function () {
      $(this.$refs.fileInput).click();
    },
    clickCoverUpload: function () {
      $(this.$refs.coverInput).click();
    },
    deleteFile: function (id) {
      this.$baseReq
        .delete(`upload/files/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$bus.token}`,
          },
        })
        .then(() => {
          let ix = this.submission.files.findIndex((f) => f.id == id);
          this.submission.files.splice(ix, 1);
        })
        .catch((e) => console.log(e));
    },
    addTeamMember: async function () {
      if (this.teamMember == "") {
        alert("Please fill all inputs");
        return;
      }
      if (this.teamMemberAge == "" && this.campaign.pageName == "LEDEG") {
        alert("Please fill all inputs");
        return;
      }
      if (this.teamMember == this.$bus.user.email) {
        alert("Cannot add yourself to your own team");
        return;
      }

      let user = await this.$req
        .get(`getUserId?email=${this.teamMember}`)
        .catch((e) => console.log(e));
      user = user.data;
      if (!this.checkEligible(user)) {
        alert("User is not eligible for this competition.");
        return;
      }

      if (
        this.campaign.sameRegionTeam &&
        this.$bus.getRegion(user.institute) !=
          this.$bus.getRegion(this.$bus.user.institute)
      ) {
        alert("All team members must be from the same region.");
        return;
      }

      if (user == "User does not exist") {
        alert("User does not have an account. Please ask user to sign up.");
      } else if (!this.$bus.checkProfileCompletion(user)) {
        alert(
          "User has incomplete profile. Please ask user to complete their profile for participation."
        );
      } else {
        this.teamMembers.push({
          ...user,
          age:
            this.campaign.pageName == "LEDEG" ? this.teamMemberAge : undefined,
        });
        this.teamMember = "";
        this.teamMemberAge = "";
      }
    },
    deleteTeamMember: function (ix) {
      this.teamMembers.splice(ix, 1);
    },
    getDateLabel: function (date) {
      return moment(date).format("HH:mm Do MMM YYYY");
    },
    createFormData(file, collection, field, source = "") {
      let data = new FormData();
      data.append("files", file);
      data.append("ref", collection);
      data.append("refId", this.submission.id);
      if (source) {
        data.append("source", source);
      }
      data.append("field", field);
      return data;
    },
    submitCover: function (ev) {
      var MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
      let files = ev.target.files[0];
      let cumulative =
        this.submission.files.reduce((s, f) => s + f.size * 1024, 0) +
        files.size;
      if (cumulative > MAX_FILE_SIZE) {
        alert("Cover size cannot exceed 5 Mb");
        return;
      }
      let notyf = new Notyf({ position: { x: "right", y: "top" } });
      notyf.success("Cover upload started");
      let data = this.createFormData(files, "event-submissions", "cover");
      this.$baseReq
        .post("upload", data, {
          headers: {
            Authorization: `Bearer ${this.$bus.token}`,
          },
        })
        .then((r) => {
          notyf.success("Cover Received");
          this.submission.cover = r.data[0];
        })
        .catch((e) => {
          notyf.success("Upload Failed");
          console.log(e);
        });
    },
    submitFile: function (ev) {
      var MAX_FILE_SIZE = 25 * 1024 * 1024; // 5MB
      let files = ev.target.files[0];
      let cumulative =
        this.submission.files.reduce((s, f) => s + f.size * 1024, 0) +
        files.size;
      if (cumulative > MAX_FILE_SIZE) {
        alert("Submissions cannot exceed 25 Mb");
        return;
      }
      let notyf = new Notyf({ position: { x: "right", y: "top" } });
      notyf.success("Submission upload started");
      let data = this.createFormData(files, "event-submissions", "files");
      this.$baseReq
        .post("upload", data, {
          headers: {
            Authorization: `Bearer ${this.$bus.token}`,
          },
        })
        .then((r) => {
          notyf.success("Submission Received");
          this.submission.files.push(r.data[0]);
          this.$req
            .post(
              "sendSubmissionEmail",
              {
                event: this.campaign.Title,
                name: this.$bus.user.name,
                to: this.$bus.user.email,
              },
              this.$bus.headers
            )
            .catch((e) => console.log(e));
        })
        .catch((e) => {
          notyf.success("Upload Failed");
          console.log(e);
        });
    },
    createSubmission: function () {
      if (this.yourAge == "" && !this.campaign.pageName == "LEDEG") {
        alert("Please enter your age");
        return;
      }

      if (!this.hasRequiredTeamMembers) {
        alert("You do not have the required number of team members");
        return;
      }

      this.$req
        .post(
          "createEventEntry",
          {
            event: this.campaign.id,
            users: [this.$bus.user.id, ...this.teamMembers.map((m) => m.id)],
            metadata:
              this.campaign.pageName == "LEDEG"
                ? {
                    ages: [this.yourAge, ...this.teamMembers.map((m) => m.age)],
                  }
                : undefined,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$bus.token}`,
            },
          }
        )
        .then((r) => {
          this.submission = r.data;
        })
        .catch((e) => {
          if (e?.response?.status == 401) {
            alert("Access token expired. Please login again.");
            this.$bus.logout();
          }
        });
      // this.$req.post('event-submissions', {
      //   event: this.campaign.id,
      //   users: [this.$bus.user.id, ...this.teamMembers.map(m => m.id)],
      //   metadata: this.campaign.pageName == 'LEDEG' ? {ages: [this.yourAge, ...this.teamMembers.map(m => m.age)]} : undefined
      // }, {
      //   headers: {
      //     'Authorization': `Bearer ${this.$bus.token}`
      //   }
      // }).then(r => {
      //   this.submission = r.data
      //   // console.log('Submission Received', this.submission)
      // }).catch(e => console.log(e))
    },
    updateSubmission: function () {
      this.$req
        .put(
          `event-submissions/${this.submission.id}`,
          {
            users: [this.$bus.user.id, ...this.teamMembers.map((m) => m.id)],
            metadata:
              this.campaign.pageName == "LEDEG"
                ? {
                    ages: [this.yourAge, ...this.teamMembers.map((m) => m.age)],
                  }
                : undefined,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$bus.token}`,
            },
          }
        )
        .then((r) => {
          this.submission = r.data;
        })
        .catch((e) => console.log(e));
    },
    updateTitle: function (evt) {
      const title = evt.target.value;
      let notyf = new Notyf({ position: { x: "right", y: "top" } });

      this.$req
        .put(
          `event-submissions/${this.submission.id}`,
          {
            title,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$bus.token}`,
            },
          }
        )
        .then((r) => {
          this.submission = r.data;
          notyf.success("Title updated");
        })
        .catch((e) => console.log(e));
    },
  },
  async mounted() {
    if (this.campaign?.Images && this.campaign?.Images?.length > 0) {
      this.src = this?.campaign?.Images?.[0];
    } else {
      this.src = this?.campaign?.Poster;
    }
    this.getFeaturedSubmissions();
    let preregister = this.$route.query.preregister;
    try {
      var firebaseConfig = {
        apiKey: "AIzaSyCz4ypQyjtGsDhcp9g1gLnZjfZ-wgmYOug",
        authDomain: "ethosempowers-2281d.firebaseapp.com",
        projectId: "ethosempowers-2281d",
        storageBucket: "ethosempowers-2281d.appspot.com",
        messagingSenderId: "905484472591",
        appId: "1:905484472591:web:68aa7104b6a69c93d36e7a",
      };
      // Initialize Firebase
      /* eslint-disable-next-line no-undef */
      firebase.initializeApp(firebaseConfig);
    } catch (e) {
      console.log(e);
    }

    if (this.campaign && this.$bus.user) {
      this.$req
        .get(
          `event-submissions/count?event.id=${this.campaign.id}&users.id=${this.$bus.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$bus.token}`,
            },
          }
        )
        .then((r) => {
          this.hasSubmission = r.data > 0;
        })
        .catch((e) => console.log(e));

      // this.$req.post(`hit/eventVisit-${this.campaign.id}`)
      //   .then(() => {
      //     console.log('Logged', moment()._d)
      //   })
      //   .catch(e => console.log(e))

      this.$req
        .get(
          `event-submissions?event.id=${this.campaign.id}&users.id=${this.$bus.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$bus.token}`,
            },
          }
        )
        .then((r) => {
          this.submission = r.data[0];
          this.loaded = true;
          if (this.submission) {
            let users = this.submission.users;
            users
              .filter((u) => u.email != this.$bus.user.email)
              .forEach((u) => {
                this.teamMembers.push(u);
              });
          } else {
            if (preregister && this.checkEligible(this.$bus.user)) {
              this.createSubmission();
            }
          }
        })
        .catch((e) => console.log(e));
      this.$req
        .get(
          `getEventsQuizEntry?id=${this.campaign.id}&userId=${this.$bus.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$bus.token}`,
            },
          }
        )
        .then((r) => {
          // console.log(r)
          this.quizEntry = r.data.quizEntry;
        })
        .catch((e) => console.log(e));
    }
    this.$bus.getLatestRoles().then((r) => {
      this.roles = r;
    });
  },
};
</script>

<style lang="css" scoped>
.chevrons {
  width: 40px;
  padding: 8px;
  background: white;
  border-radius: 40px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.12);
}
.chevrons:hover {
  background: #f5f5f5;
}
.chevrons:active {
  background: #eee;
}
.chevrons i {
  margin: auto;
}
.campaign .figure .campaign:not(.event) {
  /* background: url('../assets/campaign_smear.svg'); */
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}
.title,
.info,
.files {
  padding-left: 6%;
  padding-right: 6%;
}
a {
  display: block;
}
.participation_button {
  margin-left: 6%;
}
div.main {
  margin-left: 6%;
  width: 80%;
  height: 360px;
}
@media (min-width: 1400px) {
  div.main {
    height: 500px;
  }
}
@media (max-width: 800px) {
  a {
    font-size: 12px;
  }
}

.images {
  margin-left: 6%;
  margin-top: 24px;
  overflow-x: scroll;
  width: 80%;
  position: relative;
}
.images img {
  cursor: pointer;
  margin-right: 12px;
  margin-left: 0px;
}
.images::-webkit-scrollbar {
  display: none;
}
.campaign_img.inactive {
  opacity: 0.4;
}

/* Hide scrollbar for IE, Edge and Firefox */
.images {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.fileUpload {
  background: #eee;
  border-radius: 4px;
  padding: 12px 20px;
}
.addFileButton {
  width: 150px;
  color: #666;
  height: 50px;
  background: transparent;
  border: 1px dashed #ccc;
  transition: 0.15s;
}
.addFileButton:hover {
  background: #f4f4f4;
}
.fileView {
  display: flex;
  position: relative;
  min-width: 150px;
  height: 50px;
  border-radius: 4px;
  background: white;
}
.fileName:hover {
  color: #8d6235;
}
.fileDelete {
  color: #ccc;
}
.fileDelete:hover {
  color: tomato;
}
.hiddenOnPage {
  display: none;
}
.campaignTextNImage {
  display: flex;
  flex-wrap: wrap;
  min-height: 100px;
}

.campaignTextNImage img {
  height: -webkit-fill-available;
}
.campaignTextNImage .text {
  padding-left: 40px !important;
  height: fit-content;
  margin: auto 0px;
}

@media (max-width: 1400px) {
  .campaignTextNImage .text {
    flex-grow: 1;
  }
}

@media (max-width: 900px) {
  .campaignTextNImage img {
    width: 100%;
    height: auto;
  }
  .campaignTextNImage .text {
    width: 100%;
    padding: 40px 20px;
  }
}
</style>
